import { Apollo, QueryRef, gql } from 'apollo-angular';
import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes, TimedOutMessage } from '../../constants';
import { CaseListType } from '../../../gcs.types/gcs.types.CaseListType';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogService } from "@progress/kendo-angular-dialog";
import { environment } from '../../../../environments/environment';
import { GcsGraphQlsetbBfoLogsServiceSecure } from './gcs.graph-ql.gcs-set-bfoLogs.service.secure';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlCaseListServiceSecure implements OnDestroy {
  public concatCaseListData = [];
  public concatCaseListDataAllOpenCases = [];
  loading: boolean;
  posts: any;
  public myOpenCasePostsQuery: QueryRef<any>;
  private myOpenCaseQuerySubscription: Subscription;
  public allOpenCasesPostsQuery: QueryRef<any>;
  private allOpenCasesQuerySubscription: Subscription;
  private deleteFileFromS3: QueryRef<any>;
  public deleteQuerySubscription: Subscription;
  public getCaseListS3UrlMyOpenCases: QueryRef<any>;
  public getCaseListS3UrlAllOpenCases: QueryRef<any>;
  public getCaseListS3UrlMyOpenCasesSubscription: Subscription;
  public getCaseListS3UrlAllOpenCasesSubscription: Subscription;
  public currentDate: object;
  public errorMessage: boolean = false;
  public errorData: any = [];
  public errorMsg: any;
  public errorCode: any;
  public errorCod: any;
  public errorType: any;
  public currenDate: any;
  public userMsg: any;
  public errorName: any;
  public errorUrl: any;
  public errorhttpCod: any;
  public errorDevMsg: any;
  public isBfoFailed: boolean = false;
  public bfoError: any;
  public errorCaselist = new Subject<any>();

  public allOpenCasesPostsQueryFirst: QueryRef<any>;
  public allOpenCasesPostsQuerySubFirst: Subscription;
  bfoAllRawdata: any[];

  constructor(
    private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    public http: HttpClient,
    private dialogService: DialogService, private ngZone: NgZone,
    private gcsGraphQlsetbBfoLogsServiceSecure: GcsGraphQlsetbBfoLogsServiceSecure
  ) { }

  public async getCaseList(accessToken: string, isClosedConsider: number,
    bfoContactId: string, routerUrl: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number): Promise<any> {
      debugger
    this.concatCaseListData = [];
    this.service.checktotalCountCaseList.next(false);
    const pageSize = 2000;
    let pageNumber = 0;
    if (userGroup === 1 && levelCode === 4) {
      // It’s a limited user no BFO data are allowed
    } else {
      const args = {
        isClosedConsider,
        bfoContactId,
        levelCode,
        userGroup,
        userCode
      };
      try {
        const bfoData = this.service.getBfoDataSession() || [];
        if (bfoData && bfoData.length === 0){
          await this.getBfoDataMyOpenCases(tabIndex, routerUrl, args, 1);
        }
      } catch (error) {
        this.callCaseList(accessToken, isClosedConsider,
          bfoContactId, routerUrl, tabIndex,
          levelCode, userGroup, userCode, pageNumber, pageSize);
        this.AllRefresh();
      }
    }
    this.callCaseList(accessToken, isClosedConsider,
      bfoContactId, routerUrl, tabIndex,
      levelCode, userGroup, userCode, pageNumber, pageSize);
  }



  public async getCaseListAllOpenCases(accessToken: string, isClosedConsider: number,
    bfoContactId: string, routerUrl: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number): Promise<any> {
    this.concatCaseListDataAllOpenCases = [];
    this.service.checktotalCountCaseListAllCases.next(false);
    const pageSize = 3000;
    let pageNumber = 0;
    if (userGroup === 1 && levelCode === 4) {
      // It’s a limited user no BFO data are allowed
    } else {
      const args = {
        isClosedConsider,
        bfoContactId,
        levelCode,
        userGroup,
        userCode
      };
      if (JSON.parse(sessionStorage.getItem(SessionVariables.viewAllCases)) ||
        JSON.parse(sessionStorage.getItem(SessionVariables.companyFilter))) {
        try {
          const bfoData = this.service.getBfoDataSession() || [];
          if (bfoData && bfoData.length === 0){
            await this.getBfoDataMyOpenCases(tabIndex, routerUrl, args, 1);
          }
        } catch (error) {
          this.callCaseListAllOpenCases(accessToken, isClosedConsider,
            bfoContactId, routerUrl, tabIndex,
            levelCode, userGroup, userCode, pageNumber, pageSize);
          this.AllRefresh();
        }
      }
    }
    this.callCaseListAllOpenCases(accessToken, isClosedConsider,
      bfoContactId, routerUrl, tabIndex,
      levelCode, userGroup, userCode, pageNumber, pageSize);
    this.AllRefresh();
  }

  public callCaseListAllOpenCases(accessToken: string, isClosedConsider: number,
    bfoContactId: string, routerUrl: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number, pageNumber: number, pageSize: number) {
    let caseListQuery: QueryRef<any>;
    const caseListData: CaseListType[] = [];
    let caseResult: string;
    const session = this.service.getIds();
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    if (routerUrl === 'cmPage') {
      screenId = screenIdsForTelemetry['case-mangement-cases-home'].screenId;
    } else {
      screenId = screenIdsForTelemetry['home-overview'].screenId;
    }
    const args = {
      isClosedConsider,
      bfoContactId,
      levelCode,
      userGroup,
      userCode,
      tabIndex
    };
    this.allOpenCasesPostsQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getAllCaseGCSURL(input: {
          userGroup: ${userGroup},
          screenId: "${screenId}",
          level_code: ${levelCode},
          isMyCase: ${tabIndex},
          isClosedConsider: ${isClosedConsider},
          ipAddress: "${ip}",
          bFOContactID: "${bfoContactId}",
          JWT: "${sessionToken}"
        }) {
          signedURL
        }
      }
      `,
    });

    this.allOpenCasesQuerySubscription = this.allOpenCasesPostsQuery.valueChanges.subscribe(
      ({ data, loading }) => {
        const signedUrl = (data && data.getAllCaseGCSURL && data.getAllCaseGCSURL.signedURL) || null;
        if (signedUrl) {
          this.getCaseDataAWSAllOpenCases(signedUrl, accessToken, routerUrl, args);
        } else {
          sessionStorage.setItem(SessionVariables.myOpenCases, JSON.stringify([]));
          this.service.caseList.next(true);
        }
        // this.getS3AllOpenCases(accessToken);
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.callCaseListAllOpenCases(accessToken, isClosedConsider,
                  bfoContactId, routerUrl, tabIndex, levelCode, userGroup, userCode, pageNumber, pageSize);
              }
            });
        }
        //If excution time out error then following functionalites
        else if (errorMessage.includes(ErrorCodes.Execution_TimeOut) || errorMessage.includes('Task timed out after')) {
          this.getS3AllOpenCases(accessToken, routerUrl, args);
          this.refetchS3AllCases();
        } else {
        }
      }
    );
  }

  public callCaseList(accessToken: string, isClosedConsider: number,
    bfoContactId: string, routerUrl: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number, pageNumber: number, pageSize: number) {
    // let caseListQuery: QueryRef<any>;
    const caseListData: CaseListType[] = [];
    let caseResult: string;
    const session = this.service.getIds();
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    if (routerUrl === 'cmPage') {
      screenId = screenIdsForTelemetry['case-mangement-cases-home'].screenId;
    } else {
      screenId = screenIdsForTelemetry['home-overview'].screenId;
    }
    const args = {
      isClosedConsider,
      bfoContactId,
      levelCode,
      userGroup,
      userCode,
      tabIndex
    };
    this.myOpenCasePostsQuery = this.apollo.watchQuery({
      query: gql`
        query MyQuery {
          getAllCaseGCSURL(input: {
            userGroup: ${userGroup},
            screenId: "${screenId}",
            level_code: ${levelCode},
            isMyCase: ${tabIndex},
            isClosedConsider: ${isClosedConsider},
            ipAddress: "${ip}",
            bFOContactID: "${bfoContactId}",
            JWT: "${sessionToken}"
          }) {
            signedURL
          }
        }
        `,
      fetchPolicy: 'network-only'
    });


    this.myOpenCaseQuerySubscription = this.myOpenCasePostsQuery.valueChanges.subscribe(
      ({ data, loading }) => {
        const signedUrl = (data && data.getAllCaseGCSURL && data.getAllCaseGCSURL.signedURL) || null;
        if (signedUrl) {
          this.getCaseDataAWSMyOpenCases(signedUrl, accessToken, routerUrl, args);
        } else {
          sessionStorage.setItem(SessionVariables.myOpenCases, JSON.stringify([]));
          this.service.caseList.next(true);
        }
        // this.getS3MyOpenCases(accessToken);
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.callCaseList(accessToken, isClosedConsider,
                  bfoContactId, routerUrl, tabIndex, levelCode, userGroup, userCode, pageNumber, pageSize);
              }
            });
        }
        // If execution time out error then show popup and stop spinning
        else if (errorMessage.includes(ErrorCodes.Execution_TimeOut)) {
          this.getS3MyOpenCases(accessToken, routerUrl, args);
          this.refetchS3MyCases();
        } else {
        }
      }
    );
  }

  private getMonthValue(month: string): string {
    let monthValue = '';
    switch (month) {
      case '01': monthValue = 'JAN';
        break;
      case '02': monthValue = 'FEB';
        break;
      case '03': monthValue = 'MAR';
        break;
      case '04': monthValue = 'APR';
        break;
      case '05': monthValue = 'MAY';
        break;
      case '06': monthValue = 'JUN';
        break;
      case '07': monthValue = 'JUL';
        break;
      case '08': monthValue = 'AUG';
        break;
      case '09': monthValue = 'SEP';
        break;
      case '10': monthValue = 'OCT';
        break;
      case '11': monthValue = 'NOV';
        break;
      case '12': monthValue = 'DEC';
        break;
    }
    return monthValue;
  }

  private convertCaseListDataToDate(inputCaseListData: any[]): CaseListType[] {
    const caseListData: CaseListType[] = [];
    for (const item of inputCaseListData) {
      if (item) {
        const onlyDate = item.createdDate.toString().substring(0, 10);
        const modifiedDate = onlyDate;
        const targetDate = (item && item.paTargetDate === null) ? null : new Date(item.paTargetDate);
        if (item.paCaseType === 'Product Enhancement Request (PER)') {
          item.paCaseType = 'PER';
        }
        if (item.paCaseType === 'Technical Support (TEC)') {
          item.paCaseType = 'TEC';
        }
        if (item.paHotCase === 2) {
          item.paHotCase = 'Yes';
        } else if (!item.paHotCase) {
          item.paHotCase = 'No';
        }

        let caseStatus = item.caseStatus || item.customerStatus
        if (caseStatus !== 'Cancelled' && caseStatus !== 'Esc2TEC') {
          caseListData.push({
            paAccountSEAccountId: item.SEAccountID || item.accountSEAccountId,
            CaseDueStatusColor: item.CaseDueStatusColor,
            accountName: item.company || item.accountName,
            caseId: item.caseId || "",
            contactId: item.contactId,
            createdDate: modifiedDate,
            // above substring because the date is send with HH:mm:ss from back end, we are stripping it to keep YYYY-MM-DD(first 10 chars)
            caseNumber: item.caseNumber,
            cust_cont_id: item.Cust_Cont_ID || item.cust_cont_id,
            customerStatus: item.caseStatus || item.customerStatus,
            customer_code: item.Customer_Code,
            description: item.description,
            paCaseType: item.paCaseType,
            paTargetDate: targetDate,
            product: item.Product || item.product,
            paHotCase: item.paHotCase,
            siteName: item.sys_name || item.siteName,
            contactName: item.Cust_Cont_Name || item.contactName,
            priority: item.Impact_Desc || item.priority,
            subject: item.subject || null,
            brandName: item.BrandName || item.brandName,
            brandGroupName: item.BrandGroupName || item.brandGroupName,
            brandProductName: item.BrandProductName || item.brandProductName,
            answerProvidedToCustomer: item.response || item.answerProvidedToCustomer,
            comments: (item.History != null && item.History != '') ? item.History.trim() : item.comments,
            location: item.country,
            city: item.city,
            state: item.state,
            alarmDateAndTime: item.DateOccured || item.dateOccured,
            dateClosed: item.DateClosed,
            caseResolvingAgentName: item.fox_analyst,
            productVersion: ((item.ProductVersion != null && item.ProductVersion != '') ? item.ProductVersion.trim() : item.ProductVersion) || item.productVersion,
            caseTypeName: item.CaseTypeName,
            closedDate: item.closedDate
          });
        }
      }
    }
    return caseListData;
  }

  public refresh(): void {
    this.myOpenCasePostsQuery.refetch();
  }

  public AllRefresh(): void {
    this.allOpenCasesPostsQuery.refetch();
  }
  public deleteRefetch(): void {
    this.deleteFileFromS3.refetch();
  }

  public unsubscribe(): void {
    this.myOpenCaseQuerySubscription.unsubscribe();
    this.allOpenCasesQuerySubscription.unsubscribe();
    this.deleteQuerySubscription.unsubscribe();
  }

  ngOnDestroy(): void {
    this.myOpenCaseQuerySubscription.unsubscribe();
    this.allOpenCasesQuerySubscription.unsubscribe();
    this.deleteQuerySubscription.unsubscribe();
  }

  async getCaseDataAWSMyOpenCases(signedUrl: string, accessToken: string, routerUrl: string, args: any): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const data = await this.latestRates(signedUrl, httpOptions);
    this.dataMappingBfoAllCases(args.tabIndex, routerUrl, args, this.bfoAllRawdata, data.caseList, data.caseTypeDetails);
    this.deleteSignedUrlFromS3(accessToken);
    this.deleteRefetch();
  }

  latestRates(url, headers): Promise<any> {
    return this.http.get(url, headers).toPromise().catch(function(error) {
      if (!error.response) {
        // Display warning message GW-1886
        alert("URL is blocked! Some features of the application may not function as expected.");
        this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(this.service.getIds('user_code'), "API Event", "GCSWeb2020", "403", "CONNECTIONFAILED",
        url, "HttpErrorResponse", "Connection Failed", "Case List could not load resource", url);
      }
    });
  }

  // All open cases
  async getCaseDataAWSAllOpenCases(signedUrl: string, accessToken: string, routerUrl: string, args): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const data = await this.latestRates(signedUrl, httpOptions);
    this.dataMappingBfoAllCases(args.tabIndex, routerUrl, args, this.bfoAllRawdata, data.caseList, data.caseTypeDetails);
    this.deleteSignedUrlFromS3(accessToken);
    this.deleteRefetch();
  }

  public deleteSignedUrlFromS3(accessToken: string): void {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    this.deleteFileFromS3 = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        deleteFileFromS3(input: {JWT: "${sessionToken}"})
      }
      `,
    });
    this.deleteQuerySubscription = this.deleteFileFromS3.valueChanges.subscribe(
      ({ data, loading }) => {
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.deleteSignedUrlFromS3(accessToken);
              }
            });
        } else if (errorMessage.includes(ErrorCodes.Execution_TimeOut)) {
          //
        }
      }
    );
  }
  public handleTimeOutError() {
    this.ngZone.run(() => {
      this.dialogService
        .open({
          title: TimedOutMessage.titleMessage,
          content: TimedOutMessage.timedOutMessage,
          actions: [{ text: "OK", primary: true }],
          width: 450,
          height: 200,
          minWidth: 250,
        })
        .result.subscribe(result => {
          console.log("OK");
          // console.log(result);
        });
    });
  }
  public addTimeoutLogForAllOpenCases() {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let addLogQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery{
        addToLog(input:{
          APIName: "getAllCaseListFromS3",
          JWT: "${sessionToken}",
          ipAddress: "${ip}",
          logMessage: "${TimedOutMessage.logMessage}",
          logType: "${TimedOutMessage.logType}",
          screenId: "${screenId}"
        })
      }
      `
    });
    addLogQuery.valueChanges.subscribe(
      (result) => {
      }
    )
  }
  public addTimeoutLogForMyOpenCases() {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let addLogQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery{
        addToLog(input:{
          APIName: "myOpenCaseQuerySubscription",
          JWT: "${sessionToken}",
          ipAddress: "${ip}",
          logMessage: "${TimedOutMessage.logMessage}",
          logType: "${TimedOutMessage.logType}",
          screenId: "${screenId}"
        })
      }
      `
    });
    addLogQuery.valueChanges.subscribe(
      (result) => {
      }
    )
  }

  public getS3MyOpenCases(accessToken: string, routerUrl: string, args: any): void {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    if (routerUrl === 'cmPage') {
      screenId = screenIdsForTelemetry['case-mangement-cases-home'].screenId;
    } else {
      screenId = screenIdsForTelemetry['home-overview'].screenId;
    }
    this.getCaseListS3UrlMyOpenCases = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getCaseListS3URL(input: {
          screenId: "${screenId}",
          ipAddress: "${ip}",
          JWT: "${sessionToken}"
        }) {
          Error
          URL
        }
      }
      `,
      pollInterval: 3000
    });

    this.getCaseListS3UrlMyOpenCasesSubscription = this.getCaseListS3UrlMyOpenCases.valueChanges.subscribe(
      ({ data, loading }) => {
        const signedUrl = (data && data.getCaseListS3URL && data.getCaseListS3URL.URL) || null;
        if (signedUrl && signedUrl !== 'null') {
          this.getCaseDataAWSMyOpenCases(signedUrl, sessionToken, routerUrl, args);
          this.getCaseListS3UrlMyOpenCases.stopPolling();
        } else {
          //
        }
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              }
            });
        }
      }
    );
  }

  public refetchS3MyCases(): void {
    this.getCaseListS3UrlMyOpenCases.refetch();
  }
  public refetchS3AllCases(): void {
    this.getCaseListS3UrlAllOpenCases.refetch();
  }

  public getS3AllOpenCases(accessToken: string, routerUrl: string, args: any): void {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    if (routerUrl === 'cmPage') {
      screenId = screenIdsForTelemetry['case-mangement-cases-home'].screenId;
    } else {
      screenId = screenIdsForTelemetry['home-overview'].screenId;
    }
    this.getCaseListS3UrlAllOpenCases = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getCaseListS3URL(input: {
          screenId: "${screenId}",
          ipAddress: "${ip}",
          JWT: "${sessionToken}"
        }) {
          Error
          URL
        }
      }
      `,
      pollInterval: 3000
    });

    this.getCaseListS3UrlAllOpenCasesSubscription = this.getCaseListS3UrlAllOpenCases.valueChanges.subscribe(
      ({ data, loading }) => {
        const signedUrl = (data && data.getCaseListS3URL && data.getCaseListS3URL.URL) || null;
        const bfoCaseListError = (data && data.getCaseListS3URL && data.getCaseListS3URL.Error) || null;
        if (signedUrl && signedUrl !== 'null') {
          this.getCaseDataAWSAllOpenCases(signedUrl, sessionToken, routerUrl, args);
          this.getCaseListS3UrlAllOpenCases.stopPolling();
        }
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              }
            });
        }
      }
    );
  }


  public callCaseListAllOpenCasesFirst(): void {
    const session = this.service.getIds();
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    screenId = screenIdsForTelemetry['case-mangement-cases-home'].screenId;
    const fedId = this.service.getIds('federatedId');
    const accessToken = this.service.getIds('accessToken');
    const bfoContactId = this.service.getIds('bFOContactID');
    const levelCode = this.service.getIds('level_code');
    const userGroup = this.service.getIds('userGroup');
    const userCode = this.service.getIds('user_code');

    this.allOpenCasesPostsQueryFirst = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getAllCaseGCSURL(input: {
          userGroup: ${userGroup},
          screenId: "${screenId}",
          level_code: ${levelCode},
          isMyCase: 0,
          isClosedConsider: 1,
          ipAddress: "${ip}",
          bFOContactID: "${bfoContactId}",
          JWT: "${sessionToken}"
        }) {
          signedURL
        }
      }
      `,
    });

    this.allOpenCasesPostsQuerySubFirst = this.allOpenCasesPostsQueryFirst.valueChanges.subscribe(
      ({ data, loading }) => {
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.callCaseListAllOpenCasesFirst();
              }
            });
        }
      }
    );
  }


  // Below are the RESTfull API's for BFO cases
  async getBfoDataMyOpenCases(isMyCase: number, routerUrl: string, args: any, pageNumber: number): Promise<any> {
    debugger
    const getIds = this.service.getIds();
    const baseUrl = environment.bfoCaselistEndPoint;
    const action = `partner-case/cases?format=Export&page-size=500&page-number=${pageNumber}&accountid=&contractid=&status-filter=140&keyword-search=&sorted-by=11&sorted-order=asc&createddate-start=&createddate-end=&community-source=PA`;

    const accessWithOptions = {
      headers: new HttpHeaders({
        'X-IDMS-Authorization': `${getIds.accessToken}`,
        Authorization: `Bearer ${getIds.apigeeAccessToken}`,
        // 'X-BFO-Authorization': `${getIds.accessToken}`,
        'Content-Type': 'application/json'
      }),
    };
    let url = baseUrl + action;
    let totalData = [];
    const data = await this.myOpenCases(url, accessWithOptions);
    sessionStorage.setItem(SessionVariables.totalCountBfo, JSON.stringify(data.totalNumberOfCases));
    totalData = [...totalData, ...data.cases];
    const loopNum = data.totalNumberOfPages;
    let apiArray = [];
    if (totalData.length !== data.totalNumberOfCases || totalData.length < data.totalNumberOfCases) {
      for (let i = 1; i < loopNum; i++) {
        const actionpage = `partner-case/cases?format=Export&page-size=500&page-number=${i + 1}&accountid=&contractid=&status-filter=140&keyword-search=&sorted-by=11&sorted-order=asc&createddate-start=&createddate-end=&community-source=PA`;
        url = baseUrl + actionpage;
        apiArray.push(this.myOpenCases(url, accessWithOptions));
      }
      await Promise.all(apiArray).then((result: any) => {
        result.forEach((casesRes: any) => {
          if (casesRes) {
            totalData = [...totalData, ...casesRes.cases];
          }
        });
      });
    }
    this.bfoAllRawdata = totalData || [];
    this.service.bfoCasesSession(this.bfoAllRawdata);
    // console.log(this.bfoAllRawdata);
  }

  async myOpenCases(url, accessWithOptions): Promise<any> {
    return this.http.get(url, accessWithOptions).toPromise()
      .catch(err => {
        this.errorCaselist.next({ bfoError: err });
      });
  }

  async dataMappingBfoAllCases(isMyCase, routerUrl, args, totalData, GCSData, caseTypeDetails) {
    let getMyOpenCasesbfo: any;
    let modifyData = await this.getBFOCaseList(args, totalData, GCSData, caseTypeDetails) || [];

    if (isMyCase === 1) {
      const myCaseDataFilter = modifyData.filter((myCaseItem: any) => {
        let CFIDList = this.service.getIds().CFIDList;
        if
        ((myCaseItem && myCaseItem.contactId) === args.bfoContactId || (myCaseItem && myCaseItem.paContactCfid) === this.service.getIds().CFID.replace(/^0+/, '')
           //  || (myCaseItem && myCaseItem.bFOContactID) === args.bfoContactId
        //  ||(myCaseItem && myCaseItem.contactId) === this.service.getIds().contactId
        // ||(myCaseItem &&  myCaseItem.paContactCfid  &&this.service.getIds().CFIDList.includes(myCaseItem?.paContactCfid))
        ||(myCaseItem &&  myCaseItem.paContactCfid && CFIDList
          && CFIDList[CFIDList.split(',').indexOf(myCaseItem?.paContactCfid)] != undefined)
        ) {
          return myCaseItem;
        }
      });
      modifyData = myCaseDataFilter;
    }
    const bothGcsBfoData = [...GCSData, ...modifyData];
    // Filter duplicate cases based on Case Numbers : GW-1765
    const filteredbothGcsBfoData = bothGcsBfoData.reduce((unique, o) => {
      if (!unique.some(obj => (obj && obj.caseNumber) === (o && o.caseNumber))) {
        unique.push(o);
      }
      return unique;
    }, []);

    // const filteredbothGcsBfoData = [...bothGcsBfoData.reduce((map, obj) => map.set((obj && obj.caseNumber), obj), new Map()).values()];
    const loginData = this.service.getIds();
    const getCases = this.convertCaseListDataToDate(filteredbothGcsBfoData);
    let gcsContactName = '';
    if (loginData && loginData.CFIDList) {
      getCases.map((caseData) => {
        const custId = caseData && caseData.cust_cont_id && caseData.cust_cont_id.trim();
        if (custId && custId !== '' && loginData.CFIDList.includes(custId)) {
          caseData.cust_cont_id = loginData.CFIDList;
        }
      });
      getMyOpenCasesbfo = getCases;
    } else {
      getMyOpenCasesbfo = getCases;
    }

    if (isMyCase === 1) {
      if (routerUrl === 'cmPage') {
        this.service.sessionmyOpenCases(getMyOpenCasesbfo);
        if(getMyOpenCasesbfo && getMyOpenCasesbfo.length === 0){
          sessionStorage.setItem(SessionVariables.isZeroCasesCmMyOpenCases, JSON.stringify(true));
        }
      }else{
        this.service.sessionmyOpenCasesHome(getMyOpenCasesbfo);
        if(getMyOpenCasesbfo && getMyOpenCasesbfo.length === 0){
          sessionStorage.setItem(SessionVariables.isZeroCasesHomeMyOpenCases, JSON.stringify(true));
        }
      }
      this.service.caseList.next(true);
    } else {
      // all open cases
      if (routerUrl === 'cmPage') {
        this.service.sessionAllOpenCases(getMyOpenCasesbfo);
      } else {
        this.service.sessionAllOpenCasesHome(getMyOpenCasesbfo);
      }
      this.service.caseListAllOpenCases.next(true);
    }
  }

  async getCommercialRefList(bfoData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let commercialRefSet = new Set();

      bfoData.map((item) => {
        if (item.product !== '' && item.product !== undefined) {
          commercialRefSet.add(item.product);
        }
      });
      const inputStr = [...commercialRefSet].join('|');
      if (inputStr.length === 0) {
        resolve('');
      } else {
        const productAllInfo = await this.getCommercialRef(inputStr);
        resolve(productAllInfo);
      }
    });
  }

  async getBfoCommentList(bfoData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let commentSet = new Set();
      bfoData.map((item) => {
        if (item.caseNumber !== '' && item.caseNumber !== undefined) {
          commentSet.add(item.caseNumber);
        }
      });
      const inputStr = [...commentSet].join('|');
      if (inputStr.length === 0) {
        resolve('');
      } else {
        const commentAllInfo = await this.getBfoComment(inputStr);
        resolve(commentAllInfo);
      }
    });
  }

  // Commercial Reference API
  async getCommercialRef(inputStr: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.apollo.watchQuery({
        query: gql`
      query MyQuery {
        getCommericalReference(input: {commercialReference: "${inputStr}"}) {
            commercial_ref
            BrandCode
            BrandName
            BrandProductCode
            BrandProductName
            BrandGroupCode
            BrandGroupName
            Version_Code
            Version_Name
            CommercialReference
            ID
          }
        }
      `,
      }).valueChanges.subscribe(
        ({ data, loading }) => {
          const getData: any = data;
          resolve(getData && getData.getCommericalReference);
          this.loading = loading;
        },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            // code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.callCaseListAllOpenCasesFirst();
                }
              });
          }
        }
      );
    });
  }

  async getBFOCaseList(args, bfoData?: any, GCSData?: any, caseTypeDetails?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let closedList = (args.isClosedConsider === 0) ? ['Closed', 'Cancelled', 'SPAM', 'ESC2TEC'] : [];
      let { bFOContactID, userGroup, level_code, isMyCase } = args;
      const BFOCaseTypeDetails = [
        { fullName: 'Technical Support (TEC)', shortName: 'TEC' },
        { fullName: 'Product Enhancement Request (PER)', shortName: 'PER' },
        { fullName: 'Remote Watch System (RWS)', shortName: 'RWS' }
      ];
      let casePriorityMasterData = [
        {
          Active_CS_Prior: true,
          CS_Prior_Desc: 'Serious',
          CS_Prior_Doc: '1',
          CS_Prior_Order: 5,
          CS_Prior_WF: 3,
          id: '2'
        },
        {
          Active_CS_Prior: true,
          CS_Prior_Desc: 'Normal',
          CS_Prior_Doc: '1',
          CS_Prior_Order: 3,
          CS_Prior_WF: 3,
          id: '1'
        },
        {
          Active_CS_Prior: true,
          CS_Prior_Desc: 'High',
          CS_Prior_Doc: '1',
          CS_Prior_Order: 6,
          CS_Prior_WF: 3,
          id: '3'
        }
      ];
      let caseDueStatusMasterData = [
        {
          DueStatusCode: 0,
          DueStatusColor: 'orange',
          DueStatusName: 'Warning',
          DueStatusOrder: 20,
          id: '2'
        },
        {
          DueStatusCode: 1,
          DueStatusColor: 'red',
          DueStatusName: 'Due',
          DueStatusOrder: 10,
          id: '1'
        },
        {
          DueStatusCode: 999,
          DueStatusColor: 'cyan',
          DueStatusName: 'OOS',
          DueStatusOrder: 5,
          id: '4'
        },
        {
          DueStatusCode: -1,
          DueStatusColor: 'lime',
          DueStatusName: 'OnTrack',
          DueStatusOrder: 30,
          id: '3'
        }
      ];
      const allGcsAccountDetails = JSON.parse(sessionStorage.getItem(SessionVariables.allGcsAccountDetails)) || [];
      if (!bfoData || bfoData === undefined) {
        resolve([]);
      } else {
        // check for limited user
        if (userGroup === 1 && level_code === 4) {
          resolve([]);
        } else {
          let commRefList;
          let commentList;
          let BFOJsonData = await bfoData;
          // console.log('BFO data count before comm ref:: ', BFOJsonData.length);
          // commRefList = await this.getCommercialRefList(BFOJsonData) || [];
          // commentList = await this.getBfoCommentList(BFOJsonData) || [];

          await Promise.all([this.getCommercialRefList(BFOJsonData), this.getBfoCommentList(BFOJsonData)]).then((result: any) => {
            commRefList = result[0];
            commentList = result[1]
            // console.log(result)
          });

          if (isMyCase === 0) { // for all case returning the whole json
            let BFOList: any;
            if (BFOJsonData && BFOJsonData.length > 0) {

              BFOList = Promise.all(BFOJsonData.map(async function (item) {

                let status = item.customerStatus.trim();

                // if (closedList.indexOf(status) == -1) {
                if (closedList.indexOf(status) === -1 &&
                  !(args.isClosedConsider === 0 && item.paCaseType === 'PER' && status.toUpperCase() === 'BACKLOG')) {
                  // get company name from GCS
                  // item.accountName = await this.getAccountNameByAccountId(item, GCSData);
                  let flag = 0;
                  let companyName = null;

                  if (item.accountSEAccountId) {
                    allGcsAccountDetails.map((gcs) => {

                      if ((gcs.SEAccountID && gcs.SEAccountID.trim()) === item.accountSEAccountId) {
                        flag = 1;
                        // companyName = gcs.company.trim();
                        companyName = gcs.LocalCompany && gcs.LocalCompany.trim();
                        item.accountName = companyName;
                        // if (Number(item.paContactCfid) === Number(gcs.Cust_Cont_ID)) {
                        //   item.contactName = gcs.Cust_Cont_Name;
                        // }
                        if (Number(item.paContactCfid) === Number(gcs.Customer_Contact_Number)) {
                          item.contactName = `${gcs.FirstName} ${gcs.LastName}`;
                        }
                      }
                    });
                    if (flag !== 1) {
                      companyName = item.accountName;
                      item.accountName = companyName;
                    }
                  }

                  if (commRefList !== '' && commRefList !== undefined) {

                    let productInfo = commRefList.filter((commReffItem) => {
                      if (commReffItem.CommercialReference === item.product) {
                        return commReffItem;
                      }
                    });
                    productInfo = productInfo[0];

                    if (productInfo !== undefined && productInfo !== '') {
                      let { BrandName, BrandProductName, BrandGroupName, Version_Name } = productInfo;
                      item.brandName = BrandName;
                      item.brandProductName = BrandProductName;
                      item.brandGroupName = BrandGroupName;
                      item.productVersion = Version_Name;
                    }
                  }

                  if (commentList !== '' && commentList !== undefined) {
                    let commentsInfo = commentList.filter((commentItem) => {
                      if ((commentItem && commentItem.BfoCaseNumber && commentItem.BfoCaseNumber.trim())
                        === (item && item.caseNumber && item.caseNumber.trim())) {
                        return commentItem;
                      }
                    });

                    if (commentsInfo !== undefined && commentsInfo !== '') {
                      let Comments = commentsInfo;

                      if (Comments.length !== 0) {
                        Comments = Comments[0];
                        item.comments = Comments.Comments;
                      }
                    }
                  }

                  // for coutn match at UI
                  item.cust_cont_id = item.paContactCfid;
                  // Date format conversion to match with GCS date format
                  if (item.hasOwnProperty('paTargetDate') && item.paTargetDate !== undefined &&
                    item.paTargetDate !== '' && item.paTargetDate !== '' && item.paTargetDate != null) {
                    item.paTargetDate = item.paTargetDate.split('/').reverse().join('-');
                  }
                  // START - Adding color indicator logic
                  // let bfoCaseColor = await this.getCaseColorIndicatorData(item.paTargetDate, item.priority);
                  let dueStatusCode = null;
                  if (item.paTargetDate === undefined || item.paTargetDate === null || item.paTargetDate === '') {
                    item.paTargetDate = null;
                  }
                  if (item.priority === undefined || item.priority == null || item.priority === '') {
                    item.priority = null;
                  }

                  // get the warnign days from CasePriorityMasterData
                  let priorityDetails = casePriorityMasterData.filter((priorityMasterItem) => {
                    if (priorityMasterItem.CS_Prior_Desc === item.priority) {
                      return priorityMasterItem;
                    }
                  });

                  if (item.paTargetDate === null || item.priority === null || priorityDetails.length === 0) {
                    dueStatusCode = 999;
                  } else {
                    const date1 = new Date(item.paTargetDate);
                    const date2 = new Date(); // current date
                    // celculating diffrence in days
                    const DifferenceInTime = date2.getTime() - date1.getTime();
                    const DifferenceInDays = Math.floor(DifferenceInTime / (1000 * 3600 * 24));
                    if (DifferenceInDays < -priorityDetails[0].CS_Prior_WF) {
                      // On track lime
                      dueStatusCode = -1;
                    } else if (DifferenceInDays > priorityDetails[0].CS_Prior_WF) {
                      // Due Red
                      dueStatusCode = 1;
                    } else {
                      // Warning Orange
                      dueStatusCode = 0;
                    }
                  }
                  // Getting the color details from dueStatusMaster according to the due status
                  let dueStatusDetails = caseDueStatusMasterData.filter((dueStatusitem) => {
                    if (dueStatusitem.DueStatusCode === dueStatusCode) {
                      return dueStatusitem;
                    }
                  });
                  item.CaseDueStatusColor = dueStatusDetails && dueStatusDetails[0].DueStatusColor;
                  // End Status Color

                  // hot case logic (yes/no)
                  item.paHotCase = (item.paHotCase === '' || item.paHotCase === 'False' ||
                    item.paHotCase == null || item.paHotCase === 'No') ? 'No' : 'Yes';

                  // adding Case type fullname for tooltip
                  if (item.paCaseType !== '' || item.paCaseType != null) {
                    // item.paCaseType = this.getBFOCaseTypeShortName(item.paCaseType, caseTypeDetails);
                    const response = BFOCaseTypeDetails.filter((caseTypeitem) => {
                      if (caseTypeitem.fullName === item.paCaseType) { return caseTypeitem.shortName; }
                    });
                    if (response && response.length > 0) {
                      item.paCaseType = response[0].shortName.toString();
                    } else {
                      item.paCaseType = item.paCaseType;
                    }
                    const res = caseTypeDetails.filter((caseType) => {
                      if (caseType.caseTypeCD === item.paCaseType) { return caseType.caseTypeName; }
                    });
                    if (res.length > 0) {
                      item.caseTypeName = res[0].caseTypeName.toString();
                    } else {
                      item.caseTypeName = '';
                    }
                  }
                  // const finalItem = await this.getCaseTooltipData(item, caseTypeDetails);
                  return item;
                }
              }));
            }
            resolve(BFOList);
          } else {//my case
            let bfoMatchData = [];
            let BFOList: any;

            if (BFOJsonData && BFOJsonData.length > 0) {
              BFOList = Promise.all(BFOJsonData.map(async function (item) {

                let status = item.customerStatus.trim()
                // if (closedList.indexOf(status) == -1) {
                if (closedList.indexOf(status) === -1 &&
                  !(args.isClosedConsider === 0 && item.paCaseType === 'PER' && status.toUpperCase() === 'BACKLOG')) {
                  // get company name from GCS
                  let flag = 0;
                  let companyName = null;

                  if (item.accountSEAccountId) {
                    allGcsAccountDetails.map((gcs) => {
                      if ((gcs.SEAccountID && gcs.SEAccountID.trim()) === item.accountSEAccountId) {
                        flag = 1;
                        // companyName = gcs.company.trim();
                        companyName = gcs.LocalCompany && gcs.LocalCompany.trim();
                        item.accountName = companyName;
                        if (Number(item.paContactCfid) === Number(gcs.Customer_Contact_Number)) {
                          item.contactName = `${gcs.FirstName} ${gcs.LastName}`;
                        }
                      }
                    });
                    if (flag !== 1) {
                      companyName = item.accountName;
                      item.accountName = companyName;
                    }
                  }
                  // item.accountName = this.getAccountNameByAccountId(item, GCSData);
                  if (commRefList !== '' && commRefList !== undefined) {

                    let productInfo = commRefList.filter((commReffItem) => {
                      if (commReffItem.CommercialReference === item.product) {
                        return commReffItem;
                      }
                    });
                    productInfo = productInfo[0];
                    if (productInfo !== undefined && productInfo !== '') {
                      let { BrandName, BrandProductName, BrandGroupName, Version_Name } = productInfo;
                      item.brandName = BrandName;
                      item.brandProductName = BrandProductName;
                      item.brandGroupName = BrandGroupName;
                      item.productVersion = Version_Name;
                    }
                  }

                  if (commentList !== '' && commentList !== undefined) {
                    let commentsInfo = commentList.filter((commentItem) => {
                      if ((commentItem && commentItem.BfoCaseNumber && commentItem.BfoCaseNumber.trim())
                        === (item && item.caseNumber && item.caseNumber.trim())) {
                        return commentItem;
                      }
                    });

                    if (commentsInfo !== undefined && commentsInfo !== '') {
                      let Comments = commentsInfo;

                      if (Comments.length !== 0) {
                        Comments = Comments[0];
                        item.comments = Comments.Comments;
                      }
                    }
                  }

                  // for coutn match at UI
                  item.cust_cont_id = item.paContactCfid;
                  // Date format conversion to match with GCS date format
                  if (item.hasOwnProperty('paTargetDate') && item.paTargetDate !== undefined &&
                    item.paTargetDate !== '' && item.paTargetDate !== '' && item.paTargetDate != null) {
                    item.paTargetDate = item.paTargetDate.split('/').reverse().join('-');
                  }
                  // START - Adding color indicator logic
                  // let bfoCaseColor = await this.getCaseColorIndicatorData(item.paTargetDate, item.priority);
                  let dueStatusCode = null;
                  if (item.paTargetDate === undefined || item.paTargetDate === null || item.paTargetDate === '') {
                    item.paTargetDate = null;
                  }
                  if (item.priority === undefined || item.priority == null || item.priority === '') {
                    item.priority = null;
                  }

                  // get the warnign days from CasePriorityMasterData
                  let priorityDetails = casePriorityMasterData.filter((priorityMasterItem) => {
                    if (priorityMasterItem.CS_Prior_Desc === item.priority) {
                      return priorityMasterItem;
                    }
                  });

                  if (item.paTargetDate === null || item.priority === null || priorityDetails.length === 0) {
                    dueStatusCode = 999;
                  } else {
                    const date1 = new Date(item.paTargetDate);
                    const date2 = new Date(); // current date
                    // celculating diffrence in days
                    const DifferenceInTime = date2.getTime() - date1.getTime();
                    const DifferenceInDays = Math.floor(DifferenceInTime / (1000 * 3600 * 24));
                    if (DifferenceInDays < -priorityDetails[0].CS_Prior_WF) {
                      // On track lime
                      dueStatusCode = -1;
                    } else if (DifferenceInDays > priorityDetails[0].CS_Prior_WF) {
                      // Due Red
                      dueStatusCode = 1;
                    } else {
                      // Warning Orange
                      dueStatusCode = 0;
                    }
                  }
                  // Getting the color details from dueStatusMaster according to the due status
                  let dueStatusDetails = caseDueStatusMasterData.filter((dueStatusitem) => {
                    if (dueStatusitem.DueStatusCode === dueStatusCode) {
                      return dueStatusitem;
                    }
                  });
                  item.CaseDueStatusColor = dueStatusDetails && dueStatusDetails[0].DueStatusColor;
                  // End Status Color

                  // hot case logic (yes/no)
                  item.paHotCase = (item.paHotCase === '' || item.paHotCase === 'False' ||
                    item.paHotCase == null || item.paHotCase === 'No') ? 'No' : 'Yes';

                  // adding Case type fullname for tooltip
                  if (item.paCaseType !== '' || item.paCaseType != null) {
                    const response = BFOCaseTypeDetails.filter((caseTypeitem) => {
                      if (caseTypeitem.fullName === item.paCaseType) { return caseTypeitem.shortName; }
                    });
                    if (response && response.length > 0) {
                      item.paCaseType = response[0].shortName.toString();
                    } else {
                      item.paCaseType = item.paCaseType;
                    }
                    const res = caseTypeDetails.filter((caseType) => {
                      if (caseType.caseTypeCD === item.paCaseType) { return caseType.caseTypeName; }
                    });
                    if (res.length > 0) {
                      item.caseTypeName = res[0].caseTypeName.toString();
                    } else {
                      item.caseTypeName = '';
                    }
                  }

                  return item;
                }
              }));
            }
            resolve(BFOList);
          }
        }
      }

    });
  }

  async getBfoComment(inputStr: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.apollo.watchQuery({
        query: gql`
        query MyQuery{
         getBfoCaseListComment(input: {caseIds: "${inputStr}"}){
         CommentsID
         BfoCaseNumber
         Author
         UserIdAuthor
         CommentsFlag
         Comments
         CreatedInBfo
         CreateDate
         Modifydate
         }
       }
       `,
      }).valueChanges.subscribe(
        ({ data, loading }) => {
          const getData: any = data;
          resolve(getData && getData.getBfoCaseListComment);
          this.loading = loading;
        },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            // code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.callCaseListAllOpenCasesFirst();
                }
              });
          }
        }
      );
    });
  }


  public getAccountNameByAccountId(bfoItem, GCSData): any {
    let flag = 0;
    let companyName = null;

    if (bfoItem.accountSEAccountId) {
      GCSData.map((gcs) => {
        if (gcs.SEAccountID.trim() === bfoItem.accountSEAccountId) {
          flag = 1;
          companyName = gcs.company.trim();
          return companyName;
        }
      });
      if (flag !== 1) {
        companyName = bfoItem.accountName;
      }
    }

    return companyName;
  }

}


