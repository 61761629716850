import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DynamicComponentCreateService } from './gcs.services/gcs.business.services/dynamic-component-create.service';
import { SessionVariables, privacyPolicyUrl } from './gcs.services/constants';
import { GcsAppInitService } from '././gcs.services/gcs.business.services/gcs.app-init.service';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  public urlCodeValue: string;
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent: ElementRef<any>;
  public title = 'GCS Web 2020';
  private param: string;
  public privacyPolicyUrl: string = privacyPolicyUrl;
  apollo: any;
  public fedId: string;
  public showFooter: boolean;
  public showCookieBanner = true;
  public isNavDialogOpen = false;
  public isCookieSettingsDialogOpen = false;
  public statsCookie: boolean;
  public showLogoutWindow: boolean  = false;
  public logoutWindowOpened: boolean  = false;
  public timer = 3480;  // 1 hour - 2 minutes
  public timeLeft = 3480;
  public autoRefreshTimer = 1809; // 29 minutes
  public refreshedOnce: boolean;
  public warningTimer = 59;  // 1 minute
  public timerMinutes = 0;
  public timerSeconds = 0;
  public loginTime;
  public disableKeepMeLoggedIn: boolean = false;
  private currentUrl: string;

  constructor(private router: ActivatedRoute,
    private route: Router,
    private service: DynamicComponentCreateService,
    private gcsAppInitService: GcsAppInitService,
  ) {
    if (sessionStorage.getItem(SessionVariables.isUserLoggedIn) === '1' || sessionStorage.getItem(SessionVariables.loggedInUserDetails)) {
      this.service.loginData.next(true);
    }
    this.service.isNavDialogOpen.subscribe((data) => {
      if (data) {
        this.isNavDialogOpen = true;
      }
    });

    this.route.events.pipe

    this.loginTime = new Date(sessionStorage.getItem(SessionVariables.loginTime));
    if (sessionStorage.getItem(SessionVariables.loginTime) == null) {
      this.loginTime = new Date();
      sessionStorage.setItem(SessionVariables.loginTime, this.loginTime.toISOString());
    }
    setInterval(()=>{
      this.getTimeLeft();
    },1000)

  }

  public ngOnInit(): void {
    this.showFooter = sessionStorage.getItem('showFooter') === '1' ? true : false;
    this.showCookieBanner = localStorage.getItem('cookiePolicyAccepted') ? false : true;
    this.statsCookie = localStorage.getItem('statsCookieAccepted') === '0' ? false : true;
    this.refreshedOnce = sessionStorage.getItem(SessionVariables.refreshedOnce) === '1' ? true : false;
    if (this.refreshedOnce == false) {
      sessionStorage.setItem(SessionVariables.refreshedOnce, '0');
    }
    sessionStorage.removeItem(SessionVariables.isZeroCasesHomeMyOpenCases);
    sessionStorage.removeItem(SessionVariables.callingCaseListAppComp);
    sessionStorage.removeItem(SessionVariables.isZeroCasesCmMyOpenCases);
    this.service.loginData.subscribe(data =>{
      if(data){
        sessionStorage.removeItem(SessionVariables.companyFilter);
        const login = this.service.getIds();
        if(sessionStorage.getItem(SessionVariables.caseListTempColumns)) {
          //
        } else {
          this.gcsAppInitService.getCaselistColumns();
        }
        this.gcsAppInitService.getCaselist();
        sessionStorage.setItem(SessionVariables.callingCaseListAppComp, JSON.stringify(true));
        this.gcsAppInitService.caseFilter("");
        this.gcsAppInitService.gcsUser(login.user_code);
        this.gcsAppInitService.gcsSite(login.user_code);
        this.gcsAppInitService.gcsRemote(login.user_code, null);
        this.gcsAppInitService.gcsCreateCaseType();
        if (!sessionStorage.getItem(SessionVariables.allGcsAccountDetails)) {
          this.gcsAppInitService.getGcsAccountDetails();
        }
        // banner meesage call
        if(!JSON.parse(sessionStorage.getItem(SessionVariables.getbannerMessage))){
          this.gcsAppInitService.gcsBannerMessage();
        }

        // bell notifications
        if(!sessionStorage.getItem(SessionVariables.bellNotificationsAndAlerts)){
          this.gcsAppInitService.getBellNotificationDetails();
        }

        // overview statistics
        this.gcsAppInitService.getOverviewStatistics();

        // my company overview
        this.gcsAppInitService.myCompanyOverview();
      }
    })

    //to remove error logs from console
    window['console']['error'] = function () { };

    //Set analytics service
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    debugger;
    this.route.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      gtag('config', environment.googleAnalyticsMeasurementId,
      {
        page_path: event.urlAfterRedirects
      });
    });
  };

  public getTimeLeft(): void {
    let currentTime = new Date();
      let elapsedTime = Math.floor( ( currentTime.getTime() - this.loginTime.getTime() ) / 1000 );

      if (this.timeLeft > 0){
        this.timeLeft = this.timer - elapsedTime;
        this.timerMinutes = Math.floor(this.timeLeft / 60);
        this.timerSeconds = this.timeLeft - this.timerMinutes * 60;
      }

      if (this.timeLeft <= this.autoRefreshTimer && this.refreshedOnce == false) {
        if (this.route.url.includes('case-management/create-case') || this.route.url.includes('case-management/case-confirm') || window.location.href.includes('localhost:4200')) {
          //  Don't reload page if the user is in the middle of creating a case or is in development enviroment.
          sessionStorage.setItem(SessionVariables.refreshedOnce, '1'); // set flag to 1 to not refresh after creating a case
          this.refreshedOnce = true;
        }
        else {
          // refresh automatically
          sessionStorage.setItem(SessionVariables.refreshedOnce, '1');
          sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
          window.location.reload();
        }
      }

      if (this.timeLeft <= this.warningTimer ) {
        if (this.route.url.includes('case-management/create-case') || this.route.url.includes('case-management/case-confirm')) {
          //  Don't show logout window if the user is creating a case.
        }
        else {
          //  Show logout window only once.
          if (this.logoutWindowOpened == false) {
            this.logoutWindowOpened = true;
            this.showLogoutWindow = true;
          }
        }
      }

      if (this.timeLeft <= 5 ) {
        //  Disable the "Keep me logged in" button when 5 seconds are left.
        this.disableKeepMeLoggedIn = true;
      }

      if (this.timeLeft <= 0 ) {
        this.timeLeft = 0;
        if (this.route.url.includes('case-management/create-case') || this.route.url.includes('case-management/case-confirm')) {
          //  Don't show logout window if the user is creating a case.
        }
        else {
          this.closeLogoutWindow('yes');
        }
      }
  }

  public closeLogoutWindow(status: string): void {
    this.showLogoutWindow = false;
    if (status === 'yes') {
      //User chooses to logout or session times out.
      sessionStorage.clear();
      sessionStorage.setItem(SessionVariables.isLoggedIn, '0');
      window.location.href=environment.logOutUrl;
    }
    else {
      //User chooses to remain logged in.
      sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
      sessionStorage.setItem(SessionVariables.loginTime, null);
      sessionStorage.clear();
      window.location.reload();
      // this.currentUrl = this.router.snapshot.queryParams['state'] || '/gcsweb/home';
      // this.route.navigate([this.currentUrl]).then(() => {
      //   sessionStorage.clear();
      //   window.location.reload();
      // });
    }
  }

  public openLogoutWindow(): void {
    if (this.logoutWindowOpened == false) {
      this.logoutWindowOpened = true;
      this.showLogoutWindow = true;
    }
  }

  private isLoggedIn(): boolean { // Store values in session variable, instead of just checking the Param
    this.router.queryParams.subscribe(params => {
      this.urlCodeValue = params.code;
    });
    this.router.queryParams.subscribe(params => {
      this.param = params.code;
    });
    if (this.param === undefined) {
      if (sessionStorage.getItem(SessionVariables.code) === null) {
        sessionStorage.setItem(SessionVariables.isLoggedIn, '0');
        return false;
      }
      sessionStorage.setItem(SessionVariables.code, this.param);
      sessionStorage.setItem(SessionVariables.isLoggedIn, '1');
      return true;
    } else {
      sessionStorage.setItem(SessionVariables.code, this.param);
      sessionStorage.setItem(SessionVariables.isLoggedIn, '1');
      return true;
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth'
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth'
    });
  }

  public scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  acceptCookiePolicy(e): void {
    this.showCookieBanner = false;
    localStorage.setItem(SessionVariables.cookiePolicyAccepted, '1');
    if (this.statsCookie)
      localStorage.setItem(SessionVariables.statsCookieAccepted, '1');
    else
      localStorage.setItem(SessionVariables.statsCookieAccepted, '0');
    this.close();
  }

  public closeCancelDialog(event: string): void {
    this.service.isNavDialogOpen.next(false);
    this.isNavDialogOpen = false;
  }

  public navigate(): void {
    this.service.isNavDialogOpen.next(false);
    this.isNavDialogOpen = false;
    this.route.navigate([this.route.routerState.snapshot.url]);
  }

  openCookieSettingsPopup(): void {
    this.statsCookie = sessionStorage.getItem(SessionVariables.cookieEnabled) === '0' ? false : true;
    this.isCookieSettingsDialogOpen = true;
  }

  close(): void {
    this.isCookieSettingsDialogOpen = false;
  }

  checkStatus() {
    this.statsCookie = !this.statsCookie;
  }
}
