// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  mfaURL:'https://se--uatbfo22.sandbox.my.site.com/identity/VFP_IDMSMFASettings?app=PASupportWebsite&mfaContext=setup',
  ipify:'https://api.ipify.org/?format=json',
  production: false,
  currentConfig: 'uat',
  region: 'us-east-1',
  endPoint: 'https://ytr6rdym3reqbntdibstywdudm.appsync-api.us-east-1.amazonaws.com/graphql',
  loginUrl: 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=PASupportWebsite&response_type=code&client_id=MFWinQBtggrPzShEenlwvrvshPqfHeAcLURrLcYIBSbOZH.OIzsNWuGuUflwrxBzXRSdmiSXNspGwRRSZlyrO&redirect_uri=https://staging-pasupport.se.com/home',
  troubleLoggingInUrl: 'https://se--uatbfo22.sandbox.my.site.com/identity/VFP_IDMSSelfHelp?app=PASupportWebsite&lang=en-US&redirect_uri=https://staging-pasupport.se.com/home&response_type=code',
  changePassword: 'https://se--uatbfo22.sandbox.my.site.com/identity/idmspasswordupdate?app=PASupportWebsite',
  changeEmail: 'https://se--uatbfo22.sandbox.my.site.com/identity/changeemailmobile?app=PASupportWebsite',
  logOutUrl: 'https://secureidentity-uat.schneider-electric.com/identity/IDMS_IDPSloInit?post_logout_redirect_uri=https://staging-pasupport.se.com/pre-login&app=PASupportWebsite',
  supportUrl: 'https://staging-pasupport.schneider-electric.com/landing/landing.asp?smode=support',
  softwareUrl: 'https://staging-pasupport.schneider-electric.com/landing/landing.asp?smode=software',
  documentsUrl: 'https://staging-pasupport.schneider-electric.com/landing/landing.asp?smode=documents',
  addSiteUrl: 'https://staging-pasupport.schneider-electric.com/MyCompany/MyCompanyFrame2010.asp?mode=13',
  editSiteUrl: 'https://staging-pasupport.schneider-electric.com/MyCompany/MyCompanyFrame2010.asp?mode=12',
  // federatedSearchUrl: 'https://staging-pasupport.schneider-electric.com/fedsearch/?token=',
  federatedSearchUrl :'https://staging-pasupport.schneider-electric.com/pasearch/api/process-automation?token=',
  siteMapUrl: 'https://mphasis.invisionapp.com/share/CXYLNJJK5WQ#/screens',
  privacyAndCookiesPolicyUrl: 'https://www.se.com/us/en/about-us/legal/data-privacy.jsp',
  termsOfUserUrl: 'https://www.se.com/us/en/about-us/legal/terms-of-use.jsp',
  changeYourCookiesSettingsUrl: 'https://www.se.com/us/en/',
  privacyNotice: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSPrivacySelector?app=mySchneiderWeb&lang=en&privacyNotice=showPrivacyNotice',
  privacyPolicy: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSPrivacySelector?app=mySchneiderWeb&lang=en',
  reCaptchKey: '6LenSZgaAAAAAPMiIqEcGDnKIBB6AZfUawmL76GR',
  viewMoreNotification: 'https://staging-pasupport.schneider-electric.com/MoreNewsFrame2008.asp',
  emailGcsInfo:'mailto: systems.support@se.com',
  foxboroDCSUrl:'https://www.se.com/ww/en/work/products/industrial-automation-control/foxboro-dcs',
  processInstrumenatationUrl:'https://www.se.com/ww/en/brands/foxboro/foxboro-measurement-instrumentation-products.jsp',
  foxboroSCADA:'https://www.se.com/ww/en/product-range/63678-foxboro-scada-rtus/?parent-subcategory-id=6040&filter=business-1-industrial-automation-and-control&subNodeId=12366859713en_WW',
  triconexUrl:'https://www.se.com/ww/en/work/products/industrial-automation-control/triconex-safety-systems/',
  buyAutomationUrl:'https://www.buyautomation.com/showroom/default.asp',
  cyberSecurityUrl:'https://www.se.com/ww/en/work/solutions/cybersecurity/industrial-cybersecurity.jsp',
  learningServicesUrl: 'https://palearningservices.se.com/',
  schniderElectricUrl:'https://www.se.com/ww/en/work/solutions/industrial-process-automation/',
  baarnGenralEmail: 'mailto:EMEAGCS.support@se.com',
  baarnTechnicalSupport: 'mailto:Support-EMEA.PA@se.com',
  cairoGenralEmail: 'mailto:EMEAGCS.support@se.com',
  cairoTechnicalSupport:'mailto:Support-MENA.PA@se.com',
  americasGeneralEmail:'mailto:systems.support@se.com',
  americasTechnicalSupport:'mailto:PA.Support@se.com',
  chennaiGenralEmail:'mailto:APAC_systems.support@se.com',
  chennaiTechnicalSupport:'mailto:Support-India.PA@se.com',
  generalEmailSanghai:'mailto:APAC_systems.support@se.com',
  sanghaiTechnicalSupport:'mailto:Support-APAC.PA@se.com',
  scadaAndTelemetryGeneralEmail : 'mailto:systems.support@se.com',
  scadaandTelemetryTechnicalSupport:'mailto:PA.Support@se.com',
  scadapackTechnicalSupport:'mailto: supportTRSS@se.com',
  foxboroGeneralEmail:'mailto:systems.support@se.com',
  foxboroTechnicalSupport:'mailto:fielddevices-support@se.com',
  agent:"7fdc15d2dd7953d74afa5a1d762c73c3a750a8535a7135e87b0bbba3d73fada8040558d61a6ac6ca4e185fe68fc183562c8fd99511a2724a93596089bd6fff5d3f5ac3df3b0891eba6bcf5f2626b271f",
  seUrl: 'https://www.se.com',
  remoteServicesUrl: 'https://staging-pasupport.schneider-electric.com/IARP/RemoteServicesSiteDetailCustomer2011.asp',
  employeeLoginUrl: 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=GCSWeb&response_type=code&client_id=4dea9ca2a6aacaee570d796f6e0cf99a7c64af2fd9ba206d225f655e2a498e05c9363500D7b0000004dBjEAI&redirect_uri=https://staging-pasupport.schneider-electric.com/home.asp',
  fisrtTimeRegistrationUrl : 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=GCSWeb&response_type=code&client_id=4dea9ca2a6aacaee570d796f6e0cf99a7c64af2fd9ba206d225f655e2a498e05c9363500D7b0000004dBjEAI&redirect_uri=https://staging-pasupport.schneider-electric.com/home.asp',
  feedbackUrl: 'https://staging-pasupport.schneider-electric.com/Feedbackframe.asp',
  aboutCaseStatusUrl:'https://staging-pasupport.schneider-electric.com/case/CaseStatusDefFrame.ASP',
  legacyWebsiteLink:'https://staging-pasupport.schneider-electric.com/homec.asp',
  tecperDownloadUrl: 'https://api.qa.se.com/v1/',
  bfoCaselistEndPoint: 'https://api.qa.se.com/v1/customer-support/',
  feedbackGcs:'https://--staging-pasupport.schneider-electric.com/feedbackgcs.asp',
  errorBfoJson : 'assets/Error-handling/Error-Handling_withAPI_URL_UAT.json',
  seGcsProgAccessKeyId :'',
  seGcsProgSecret :'',
  googleAnalyticsMeasurementId:'G-ZFTE6M99XG',
  feedbackFromEmail:"GCSMaster@se.com",
  feedbackMailSource:"GCSWeb2020",
  secretApiKey:"apikey",
  numberOfCharToTruncateAfter:50


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
