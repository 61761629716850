import { Injectable } from '@angular/core';
// import * as crypto from 'crypto';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { parseIv } from 'src/utils/iv-parser';
import { parseSecret } from 'src/utils/secret-parser';
import { parseText } from 'src/utils/text-parser';

@Injectable({
  providedIn: 'root'
})
export class GcsApolloInitService {

  constructor() { }

  private decryptString(encrypted: string): string {
    const iv = parseIv(encrypted);
    const key = parseSecret(encrypted);
    const encryptedText = parseText(encrypted);

    const result = CryptoJS.AES.decrypt(
      { ciphertext: encryptedText },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    const resultUtf8 = result.toString(CryptoJS.enc.Utf8);

    if (!resultUtf8) {
      console.error("Error occured");
      throw new Error("Error occured");
    }

    return resultUtf8;
  }

  public getSecret(): string {
    const agent = environment.agent;
    return this.decryptString(agent);
  }

}
